import type { IntRange } from 'type-fest';

export interface OpenAiChatTaskConfig {
  type: 'chat';
  temperature?: IntRange<0, 21>;
  model?: AllowedModels;
  maxTokens?: number;
  systemMessage?: string;
}

export interface OpenAiSmartAssistantConfig {
  type?: 'assistant';
  model?: AllowedModels;
  temperature?: IntRange<0, 21>;
  maxTokens?: number;
  instructions?: string;
  openAiAssistantId?: string;
}

export interface OpenAiSmartAssistantTaskConfig {
  model?: AllowedModels;
  temperature?: IntRange<0, 21>;
  maxTokens?: number;
  additionalInstructions?: string;
}

export type OpenAiMergedConfig = OpenAiSmartAssistantConfig & OpenAiSmartAssistantTaskConfig;

export interface FunctionDefinition {
  name: string;
  description: string;
  eventToCall: string;
  groups: string[];
  paramMap?: Record<string, string>;
}

export enum AllowedModels {
  Gpt4o = 'gpt-4o',
  Gpt4o_2024_08_06 = 'gpt-4o-2024-08-06',
  Gpt4o_2024_05_13 = 'gpt-4o-2024-05-13',
  ChatGpt4oLatest = 'chatgpt-4o-latest',
  Gpt4oMini = 'gpt-4o-mini',
  Gpt4oMini_2024_07_18 = 'gpt-4o-mini-2024-07-18',
  Gpt4Turbo = 'gpt-4-turbo',
  Gpt4Turbo_2024_04_09 = 'gpt-4-turbo-2024-04-09',
  Gpt40125Preview = 'gpt-4-0125-preview',
  Gpt4TurboPreview = 'gpt-4-turbo-preview',
  Gpt41106Preview = 'gpt-4-1106-preview',
  Gpt40314 = 'gpt-4-0314',
  Gpt40613 = 'gpt-4-0613',
  Gpt432k = 'gpt-4-32k',
  Gpt432k_0314 = 'gpt-4-32k-0314',
  Gpt432k_0613 = 'gpt-4-32k-0613',
  Gpt35Turbo = 'gpt-3.5-turbo',
  Gpt35Turbo_16k = 'gpt-3.5-turbo-16k',
  Gpt35Turbo_0301 = 'gpt-3.5-turbo-0301',
  Gpt35Turbo_0613 = 'gpt-3.5-turbo-0613',
  Gpt35Turbo_1106 = 'gpt-3.5-turbo-1106',
  Gpt35Turbo_0125 = 'gpt-3.5-turbo-0125',
  Gpt35Turbo16k_0613 = 'gpt-3.5-turbo-16k-0613',
}
