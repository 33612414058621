import { GuestTypeEnum, IGuest, InboxStatusEnum, IReservation, IRoom, ReservationStatusEnum } from '@smarttypes/hotel';
import { add, sub } from 'date-fns';

const room: IRoom = {
  _id: '123456789',
  companyId: '',
  roomNo: '',
  roomType: 'Wonderful Stay',
  externalIntegrationNames: [],
  tags: [],
  notes: [],
  files: [
    {
      fileName: 'preview-room-cover.jpg',
      url: '/assets/images/preview-room-cover.jpg',
      description: 'big',
      contentType: 'Bed',
      _id: '123456789',
      createdAt: new Date(),
      updatedAt: new Date(),
    },
  ],
  createdAt: new Date(),
  updatedAt: new Date(),
  apartmentInfo: {
    size: '25',
    cleaningPrice: '',
    canSleepMax: 2,
    address: {
      geo: {
        lat: 51.6911147,
        lng: -0.4193282,
      },
    },
    host: '',
    email: '',
    phone: '',
    howToArrive: {
      en:
        "If you're arriving at Central Railway Station and want to get to the Wonderful Stay Apartments, you can follow these steps:\n" +
        '\n' +
        '1. Head West: The Wonderful Stay Apartments is located to the west of the Central Railway Station. From the station, you should head west toward the city center.\n' +
        '\n' +
        "2. Walk Along Prinsengracht: Wonderful Stay Apartments is situated along the Prinsengracht canal. To reach it, you can walk along Prinsengracht Street. It's approximately a 20-minute walk from the station.\n" +
        '\n' +
        'Please note that you can also use public transportation options like trams or buses if you prefer not to walk the distance. ',
    },
    checkInFrom: '14:00',
    checkOutUntil: '10:00',
    distances: [],
    parking: {
      parkingInfo: {
        en: 'Free',
      },
    },
    additionalInfo: {
      teaser: {
        en: 'Wonderful Stay Apartments is a two-bedroom apartment that includes a living area, two separate bedrooms, a kitchen, one bathrooms, dining space and  a balcony.',
      },
      description: {
        en: 'The beautiful, spacious suite located on the ground floor consists of a living room and a bedroom with an adjusting large terrace leading to the garden.',
      },
      address: {
        en: '4 Privet Drive, Watford, WD25 7LR, United Kingdom',
      },
      generalDescription: {
        en: 'Wonderful Stay Apartments is a two-bedroom apartment that includes a living area, two separate bedrooms, a kitchen, one bathrooms, dining space and  a balcony.',
      },
      areaInfo: {
        en:
          'The Wonderful Stay Apartments is located in the historic city center, which is a vibrant and picturesque part of the city. Here is some information about the area around: \n' +
          '\n' +
          'Canal District: Wonderful Stay Apartments is situated in the beautiful canal district. The canals in this area are part of the UNESCO World Heritage site and are lined with charming 17th-century canal houses, many of which have been well-preserved. The area offers a unique and scenic view of architectural history-view.\n' +
          '\n' +
          "Historic Buildings: Besides the Wonderful Stay Apartments you'll find many other historic buildings and museums in the vicinity. This area is rich in cultural and historical significance, making it a great place to explore.\n" +
          '\n' +
          'Shops and Restaurants: The area around the Wonderful Stay Apartments is home to a variety of shops, cafes, and restaurants. You can enjoy cuisine, shop for souvenirs, or simply stroll along the picturesque streets.',
      },
      meals: {
        en: [],
      },
      linkToPhotoImage: '/assets/images/preview-room-cover.jpg',
    },
    amenitiesPerLang: {
      en: [
        'Fully equipped kitchen',
        'Laundry facilities',
        'Outdoor spaces like patio',
        'grill areas',
        'Linens and toiletries',
        'Wi-Fi and cable/satellite TV',
        'Local information and recommendations',
      ],
    },
    wifi: {
      ssid: 'Wonderful Stay Apartments',
      password: 'AlwaysGreatStay',
    },
  },
  config: {
    roomType: {
      roomtypeid: 1,
    },
  },
};

export const GUEST: IGuest = {
  _id: 'preview',
  companyId: '63ee36c9c58cbffa5d768825',
  guestType: GuestTypeEnum.guest,
  guestStatus: 'active',
  inbox: {
    status: InboxStatusEnum.active,
    priority: 0,
    assignedTo: [],
  },
  person: {
    firstName: 'Guest',
    lastName: 'Name',
    language: 'en',
  },
  communication: {
    unreadMessagesCount: 0,
    channelIds: [],
    threadIds: [],
  },
  tags: [],
  reservation: {
    tasks: [],
    from: sub(new Date(), {
      days: 2,
    }).toISOString() as unknown as Date,
    to: add(new Date(), {
      days: 3,
    }).toISOString() as unknown as Date,
    reservationId: '123456789',
    room,
    status: ReservationStatusEnum.reserved,
  } as IReservation,
  reservations: [],
  notes: [],
  createdAt: new Date(),
  updatedAt: new Date(),
  customInfo: [],
};
