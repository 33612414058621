import { format, isBefore, isSameMonth, isSameYear } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { setLocale } from './date-fns-set-locale';

/**
 * Extracts and formats the stay range based on the provided dates, times, and settings.
 *
 * @return {string} The formatted stay range string including dates and optional times.
 */
export function stayRange(
  dateFrom: Date | string | undefined,
  dateTo: Date | string | undefined,
  timezone = 'UTC',
  timeFrom?: string,
  timeTo?: string,
  language?: string,
): string {
  if (!language) {
    language = 'en';
  }
  if (!dateFrom || !dateTo) {
    return '';
  }
  if (isBefore(dateTo, dateFrom)) {
    return '';
  }
  if (dateFrom instanceof Date) {
    dateFrom.toISOString();
  }
  if (dateTo instanceof Date) {
    dateTo.toISOString();
  }
  const from: Date = toZonedTime(dateFrom as string, timezone);
  const to: Date = toZonedTime(dateTo as string, timezone);

  if (!from && !to) {
    return '';
  }

  const startDate = format(from, 'MMM d', {
    locale: setLocale(language),
  });
  const startTime = timeFrom ? '(' + timeFrom + ')' : '';
  const endDate = format(to, 'MMM d', {
    locale: setLocale(language),
  });
  const endTime = timeTo ? '(' + timeTo + ')' : '';

  if (isSameYear(from, to)) {
    if (isSameMonth(from, to)) {
      return `${startDate}${startTime} - ${format(to, 'd')}${endTime}`;
    } else {
      return `${startDate}${startTime} - ${endDate}${endTime}`;
    }
  } else {
    return `${startDate}${startTime} - ${endDate}${endTime}`;
  }
}
