import { endOfMonth, format, startOfMonth, sub } from 'date-fns';

export enum RangeType {
  lastMonth = 'last_month',
  currentMonth = 'current_month',
}

/**
 * Retrieves the selected date ranges based on the given parameters.
 *
 * @param {string} selectedDays - The selected range type.
 * @param {string[]} fromRangeValue - The input date range value.
 * @param {string} [dateFormat='YYYY-MM-DD'] - The desired date format.
 * @param {number} [defaultValue=30] - The default value for the range in days.
 * @param {string} [initialStartDate=''] - The initial start date.
 * @param {string} [initialEndDate=''] - The initial end date.
 * @returns {Object} An object containing the start date and end date of the selected range.
 */

export function getSelectRanges(
  selectedDays: string,
  fromRangeValue: string[],
  dateFormat = 'yyyy-MM-dd',
  defaultValue = 30,
  initialStartDate = '',
  initialEndDate = '',
): { startDate: string; endDate: string } {
  const now = new Date();

  if (fromRangeValue?.length > 1) {
    return {
      startDate: format(new Date(fromRangeValue[0]), dateFormat),
      endDate: format(new Date(fromRangeValue[1]), dateFormat),
    };
  }

  if (!initialStartDate && !initialEndDate) {
    return {
      startDate: format(sub(now, { days: defaultValue }), dateFormat),
      endDate: format(now, dateFormat),
    };
  }

  if (selectedDays === RangeType.lastMonth) {
    return {
      startDate: format(startOfMonth(sub(now, { months: 1 })), dateFormat),
      endDate: format(endOfMonth(sub(now, { months: 1 })), dateFormat),
    };
  }

  if (selectedDays === RangeType.currentMonth) {
    return {
      startDate: format(startOfMonth(now), dateFormat),
      endDate: format(endOfMonth(now), dateFormat),
    };
  }

  if (selectedDays && !isNaN(Number(selectedDays))) {
    return {
      startDate: format(sub(now, { days: Number(selectedDays) }), dateFormat),
      endDate: format(now, dateFormat),
    };
  }

  return {
    startDate: '',
    endDate: '',
  };
}

export const rangeItems = [
  {
    label: 'SH.ANALYTICS_VIEW.CURRENT_MONTH',
    value: 'current_month',
  },
  {
    label: 'SH.ANALYTICS_VIEW.LAST_MONTH',
    value: 'last_month',
  },
  {
    label: 'SH.ANALYTICS_VIEW.LAST30_DAYS',
    value: '30',
  },
  {
    label: 'SH.ANALYTICS_VIEW.LAST90_DAYS',
    value: '90',
  },
  {
    label: 'SH.ANALYTICS_VIEW.RANGE',
    value: 'range',
  },
];
