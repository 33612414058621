import { CommunicationChannelEnum, ICommunicationChannelId } from '@smarttypes/core';
import { get } from 'lodash';

/**
 * Returns the communication channel from the given object based on the provided channel.
 *
 * @param {unknown} obj - The object to retrieve the communication channel from.
 * @param {CommunicationChannelEnum} channel - The channel to search for in the object's communication channels.
 * @param {string} [path='communication.channelIds'] - The optional path to the communication channel property in the object.
 * @return {ICommunicationChannelId | undefined} The communication channel object matching the provided channel, or undefined if not found.
 */

export function getCommunicationChannel(
  obj: unknown,
  channel: CommunicationChannelEnum,
  path = 'communication.channelIds',
): ICommunicationChannelId | undefined {
  return (get(obj, path, []) as ICommunicationChannelId[]).find(c => c.channel === channel);
}
