import { HttpClient } from '@angular/common/http';
import {
  CreateCategoriesEnum,
  SetExtraKnowledgeTextRequest,
  SetExtraKnowledgeTextResponse,
  TextResource,
  UpdateExtraKnowledgeTextRequest,
} from '@smart-assistant/types/v2';
import { CrossMongoId } from '@smarttypes/cross-mongo';
import { Observable } from 'rxjs';

export class TextService {
  constructor(private readonly httpClient: HttpClient) {}

  list(category: CreateCategoriesEnum): Observable<TextResource[]> {
    return this.httpClient.get<TextResource[]>(`/smart-assistant/client/knowledge/extra/${category}`);
  }

  get(category: CreateCategoriesEnum, id: CrossMongoId): Observable<TextResource> {
    return this.httpClient.get<TextResource>(`/smart-assistant/client/knowledge/extra/${category}/${id}`);
  }

  add(category: CreateCategoriesEnum, payload: SetExtraKnowledgeTextRequest) {
    return this.httpClient.post<SetExtraKnowledgeTextResponse>(
      `/smart-assistant/client/knowledge/extra/${category}`,
      payload,
    );
  }

  update(category: CreateCategoriesEnum, id: CrossMongoId, payload: UpdateExtraKnowledgeTextRequest) {
    return this.httpClient.patch<SetExtraKnowledgeTextResponse>(
      `/smart-assistant/client/knowledge/extra/${category}/${id}`,
      payload,
    );
  }

  delete(category: CreateCategoriesEnum, id: CrossMongoId) {
    return this.httpClient.delete<void>(`/smart-assistant/client/knowledge/extra/${category}/${id}`);
  }
}
