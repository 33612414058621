import { CommunicationChannelEnum, ICommunicationChannelId } from '@smarttypes/core';
import { get } from 'lodash';

/**
 * Checks if the given object has a specific communication channel.
 *
 * @param {unknown} obj - The object to check.
 * @param {CommunicationChannelEnum} channel - The communication channel to check for.
 * @param {string} [path='communication.channelIds'] - The object path where the communication channel is located.
 * @return {boolean} - Returns true if the object has the specified communication channel, false otherwise.
 */

export function hasCommunicationChannel(
  obj: unknown,
  channel: CommunicationChannelEnum,
  path = 'communication.channelIds',
): boolean {
  return !!(get(obj, path, []) as ICommunicationChannelId[]).find(c => c.channel === channel);
}
