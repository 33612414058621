import { IPagination } from '@smarttypes/api';
import { CommunicationChannelEnum } from '@smarttypes/core';

import { GuestTypeEnum } from './guest-type.enum';
import { ReservationStatusEnum } from './reservation';

export enum GuestSorting {
  checkInAsc = 'checkInAsc',
  checkInDesc = 'checkInDesc',
  checkedOutAsc = 'checkedOutAsc',
  checkedOutDesc = 'checkedOutDesc',
  apartmentLeftAtAsc = 'apartmentLeftAtAsc',
  apartmentLeftAtDesc = 'apartmentLeftAtDesc',
  enteredApartmentAtAsc = 'enteredApartmentAtAsc',
  enteredApartmentAtDesc = 'enteredApartmentAtDesc',
  changedAsc = 'changedAsc',
  changedDesc = 'changedDesc',
  createdAsc = 'createdAsc',
  createdDesc = 'createdDesc',
  gaVisitedAsc = 'gaVisitedAsc',
  gaVisitedDesc = 'gaVisitedDesc',
  firstNameAsc = 'firstNameAsc',
  firstNameDesc = 'firstNameDesc',
  lastNameAsc = 'lastNameAsc',
  lastNameDesc = 'lastNameDesc',
  registrationCardSignedAtAsc = 'registrationCardSignedAtAsc',
  registrationCardSignedAtDesc = 'registrationCardSignedAtDesc',
}

export enum GuestFindListTypeEnum {
  allActive = 'allActive',
  arrivals = 'arrivals',
  inHouse = 'inHouse',
  blocked = 'blocked',
  cancelled = 'cancelled',
  archived = 'archived',
  checkOut = 'checkOut',
  checkedOut = 'checkedOut',
  todayCheckedOut = 'todayCheckedOut',
}

export interface IGuestListParams extends Omit<IPagination, 'sort' | 'sortDirection'> {
  apartmentLeft?: boolean;
  emailFilled?: boolean;
  guestAreaVisited?: boolean;
  highlighted?: boolean;
  linkSent?: boolean;
  marketingConsents?: boolean;
  phoneFilled?: boolean;
  registrationCardFilled?: boolean;

  communicationChannels?: CommunicationChannelEnum[];

  checkInFrom?: Date;
  checkInTo?: Date;
  checkedOutFrom?: Date;
  checkedOutTo?: Date;
  guestAreaVisitedFrom?: Date;
  guestAreaVisitedTo?: Date;
  createdAtFrom?: Date;
  createdAtTo?: Date;
  updatedAtFrom?: Date;
  updatedAtTo?: Date;

  reservationStatus?: ReservationStatusEnum[];

  building?: string;
  department?: string;
  nationality?: string;
  surveyTitle?: string;
  roomAssigned?: string;
  search?: string;

  ids?: string[];
  roomIds?: string[];
  tags?: string[];

  type?: GuestFindListTypeEnum;

  guestType?: GuestTypeEnum;

  sorting?: GuestSorting;
}
