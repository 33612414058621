import { HttpClient } from '@angular/common/http';
import {
  FileResource,
  SetExtraKnowledgeFileRequest,
  SetExtraKnowledgeFileResponse,
  UpdateExtraKnowledgeFileRequest,
} from '@smart-assistant/types/v2';
import { CrossMongoId } from '@smarttypes/cross-mongo';
import { Observable } from 'rxjs';

export class FileService {
  constructor(private readonly httpClient: HttpClient) {}

  list(): Observable<FileResource[]> {
    return this.httpClient.get<FileResource[]>(`/smart-assistant/client/knowledge/extra-files`);
  }

  get(id: CrossMongoId): Observable<FileResource> {
    return this.httpClient.get<FileResource>(`/smart-assistant/client/knowledge/extra-files/${id}`);
  }

  add(payload: SetExtraKnowledgeFileRequest) {
    return this.httpClient.post<SetExtraKnowledgeFileResponse>(
      `/smart-assistant/client/knowledge/extra-files`,
      payload,
    );
  }

  update(id: CrossMongoId, payload: UpdateExtraKnowledgeFileRequest) {
    return this.httpClient.patch<SetExtraKnowledgeFileResponse>(
      `/smart-assistant/client/knowledge/extra-files/${id}`,
      payload,
    );
  }

  delete(id: CrossMongoId) {
    return this.httpClient.delete<void>(`/smart-assistant/client/knowledge/extra-files/${id}`);
  }
}
