import { HttpClient } from '@angular/common/http';
import { FormatQARequest, FormatQAResponse } from '@smart-assistant/types/v2';

export class FormatQa {
  constructor(private readonly httpClient: HttpClient) {}

  format(payload: FormatQARequest) {
    return this.httpClient.post<FormatQAResponse[]>(`/smart-assistant/client/knowledge/helper/format-qa`, payload);
  }
}
