import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { map, of, take, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { CompanyService } from '../services/company.service';

@Injectable({
  providedIn: 'root',
})
export class SwitchCompanyGuard implements CanActivate {
  readonly authService = inject(AuthService);
  readonly companyService = inject(CompanyService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const companyId = route.queryParamMap.get('companyId');

    if (!companyId) {
      return true;
    }

    return this.companyService.$currentUser().pipe(
      take(1),
      map(company => company._id as string),
      switchMap(id => {
        if (!id || id === companyId) {
          return of(true);
        } else {
          return this.authService.switch(companyId).pipe(
            tap(() => {
              window.location.assign(state.url);
            }),
            map(() => false),
          );
        }
      }),
    );
  }
}
