import { toZonedTime } from 'date-fns-tz';

/**
 * Converts a given date from UTC to the specified timezone.
 *
 * @return {Date | null} Returns the date converted to the specified timezone or null if the input date is not provided.
 */
export function utcToTimezone(date?: Date, timezone?: string): Date | null {
  if (!date) {
    return null;
  }
  if (timezone) {
    return toZonedTime(date, timezone);
  }
  return date;
}
