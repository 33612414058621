import { Inject, Injectable } from '@angular/core';
import { get } from 'lodash';

import { ISmartUiControlModuleConfig } from './smart-ui-control.module';

@Injectable({
  providedIn: 'root',
})
export class SmartUiControlService {
  constructor(@Inject('config') private readonly config: ISmartUiControlModuleConfig) {}

  hasUiEnabled(key: string): boolean {
    const value = get(this.config, key, false);
    return value !== false && value !== 'false' && value !== undefined;
  }
}
