import { IRoom } from '@smarttypes/hotel';
import { get } from 'lodash';

/**
 * Returns the apartment name based on the given room details and company name.
 *
 * @param {IRoom} room - The room details containing the apartment information.
 * @param {string} companyName - The name of the company.
 *
 * @returns {string} The apartment name. If the apartment name is available, it will be trimmed and returned.
 * If the apartment name is not available, the company name will be returned instead.
 */

export function getApartmentName(room: IRoom, companyName: string): string {
  const apartmentName = get(room, 'apartmentInfo.additionalInfo.propertyName') as string | undefined;

  if (apartmentName) {
    return apartmentName.trim();
  }

  return companyName;
}
