import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { IsUiEnabledDirective } from './is-ui-enabled.directive';
import { SmartUiControlService } from './smart-ui-control.service';

export type ISmartUiControlModuleConfig = {
  [key: string]: unknown | ISmartUiControlModuleConfig;
};

/**
 * The SmartUiControlModule is responsible for providing a UI control directive that enables or disables UI elements based on a configuration.
 *
 * @NgModule decorator is used to define the module.
 *
 *
 * E.g.:
 *
 * ``
 * SmartUiControlModule.forRoot(environments.ui)
 * ``
 *
 *
 */
@NgModule({
  imports: [CommonModule],
  declarations: [IsUiEnabledDirective],
  providers: [SmartUiControlService],
  exports: [IsUiEnabledDirective],
})
export class SmartUiControlModule {
  static forRoot(config: ISmartUiControlModuleConfig): ModuleWithProviders<SmartUiControlModule> {
    return {
      ngModule: SmartUiControlModule,
      providers: [{ provide: 'config', useValue: config }],
    };
  }
}
