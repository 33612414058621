export enum AppModules {
  guests = 'guests',
  apartments = 'apartments',
  apartmentsEditor = 'apartmentsEditor',
  rooms = 'rooms',
  unifiedInbox = 'unified-inbox',
  qrMaterials = 'qr-materials',
  visitorsArea = 'visitors-area',
  mediaLibrary = 'media-library',
  users = 'users',
  sendLink = 'send-link',
  smartAutomation = 'smart-automation',
  guestJourney = 'guest-journey',
  cancelledReservations = 'cancelled-reservations',
  analytics = 'analytics',
  guestsExport = 'guests-export',
  guestsImport = 'guests-import',
  guestsReservations = 'guestsReservations',
  guestsTags = 'guestsTags',
  qrStands = 'qrStands',
  payments = 'payments',
  massMessaging = 'massMessaging',
  requests = 'requests',
  tasks = 'tasks',
  assign = 'assign',
  registrationForm = 'registrationForm',
  registrationFormRequired = 'registrationFormRequired',
  propertiesImport = 'propertiesImport',
  billing = 'billing',
  settings = 'settings',
  propertiesExport = 'propertiesExport',
  godMode = 'godMode',
  smartAssistant = 'smartAssistant',
  smartAssistantDemo = 'smartAssistantDemo',
  smartAssistantAutoRespond = 'smartAssistantAutoRespond',
  visitorsPlaceModule = 'visitorsPlaceModule',
  automationSettings = 'automationSettings',
  automationSettingsCustom = 'automationSettingsCustom',
  integrations = 'integrations',
  buildings = 'buildings',
  visitorsAreaExtendStay = 'visitors-area-extend-stay',
  ctaToManualRequest = 'visitors-area-cta-to-manual-request',
  constantWindowOpenWA = 'communication-constant-open-wa-window',
  extendWifiApartments = 'extend-wifi-apartments',
  surveyForm = 'survey-form',
  doorCode = 'door-code',
  owners = 'owners',
  smartAssistantV3 = 'smartAssistantV3',
  smartAssistantV3Settings = 'smartAssistantV3Settings',
}
