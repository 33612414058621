import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { CustomToastrComponent } from './custom-toastr.component';

export interface ICustomToastConfig {
  message: string;
  pending?: boolean;
  icon?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  readonly toastrService = inject(ToastrService);
  readonly translateService = inject(TranslateService);

  info(message: string) {
    this.toastrService.info(this.translateService.instant(message));
  }

  error(message: string) {
    this.toastrService.error(this.translateService.instant(message));
  }

  success(message: string) {
    this.toastrService.success(this.translateService.instant(message));
  }

  clear() {
    this.toastrService.clear();
  }

  custom(config: ICustomToastConfig) {
    const toast = this.toastrService.info(this.translateService.instant(config.message), '', {
      disableTimeOut: config.pending,
      closeButton: false,
      toastComponent: CustomToastrComponent,
      tapToDismiss: !config.pending,
      timeOut: 2500,
    });
    toast.toastRef.componentInstance.pending = config.pending ?? false;
    toast.toastRef.componentInstance.icon = config.icon ?? '';
  }
}
