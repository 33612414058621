import { IAccount } from '@smarttypes/core';

/**
 * Retrieves the display name signature for the given account.
 *
 * @param {IAccount} account - The account object.
 * @return {string} - The display name signature.
 */

export function getDisplayNameSignature(account: IAccount): string {
  const parts: string[] = [];

  if (account?.person?.firstName) {
    parts.push(account.person.firstName);
    parts.push(account.person?.lastName ?? '');
  } else if (account?.userName) {
    parts.push(account.userName);
  } else if (account?.email) {
    parts.push(account.email);
  } else if (account?.phone) {
    parts.push(account.phone);
  }

  return parts
    .map(part => part.trim())
    .filter(f => f !== '')
    .join(' ');
}
