export enum SmartCoreAssistantStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
}

export enum ResourceStatus {
  QUEUED = 'QUEUED',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  READY = 'READY',
  DELETED = 'DELETED',
}

export enum KnowledgeDatabaseStatus {
  QUEUED = 'QUEUED',
  PROCESSING = 'PROCESSING',
  READY = 'READY',
  PAUSED = 'PAUSED',
}
