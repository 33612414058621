import { HttpClient } from '@angular/common/http';
import { AcceptSuggestionsRequest, SuggestAnswerRequest, SuggestAnswerResponse } from '@smart-assistant/types/v2';

export class GuestService {
  constructor(private readonly httpClient: HttpClient) {}

  suggestChatResponse(payload: SuggestAnswerRequest) {
    return this.httpClient.post<SuggestAnswerResponse>('/smart-assistant/client/assistant/suggest/answer', payload);
  }

  acceptChatResponse(payload: AcceptSuggestionsRequest) {
    return this.httpClient.post<void>('/smart-assistant/client/assistant/suggest/answer/accepted', payload);
  }

  suggestChatResponseStream(payload: SuggestAnswerRequest) {
    return this.httpClient.post<SuggestAnswerResponse>('/smart-assistant/client/assistant/suggest/answer', payload, {
      observe: 'events',
      responseType: 'json',
      reportProgress: true,
    });
  }
}
