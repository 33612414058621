import { Pipe, PipeTransform } from '@angular/core';
import { IPerson } from '@smarttypes/core';

@Pipe({
  name: 'fullName',
  standalone: true,
})
export class FullNamePipe implements PipeTransform {
  transform(person?: IPerson): string {
    return person ? `${person?.firstName} ${person?.lastName || ''}` : '-';
  }
}
