import { CrossMongoId } from '@smarttypes/cross-mongo';

import { AssistantResponse } from './assistant-response';

export enum SuggestionMessageRole {
  User = 'user',
  Assistant = 'assistant',
}

export interface InstructionsParam {
  name: string;
  value: string;
}

export interface SuggestionMessage {
  objectId: CrossMongoId;
  role: SuggestionMessageRole;
  content: string;
  date: Date;
}

export interface SuggestionMessageResponse {
  objectId: CrossMongoId;
  refTo: CrossMongoId[];
  role: SuggestionMessageRole;
  content: string;
}

export interface SuggestAnswerRequest {
  messages: SuggestionMessage[];
  answerTo: CrossMongoId[];
  contextObjectId?: CrossMongoId; // guest id
  knowledgeDatabase?: string;
  skipCache?: boolean;
  instructionsParams?: InstructionsParam[];
}

export type SuggestAnswerResponse<T = SuggestionMessageResponse[]> = AssistantResponse<T>;
