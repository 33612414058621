import { HttpEvent, HttpEventType, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { SmartCoreAssistantStatus } from '@smart-assistant/types/v2';
import { catchError, Observable, tap, throwError } from 'rxjs';

import { SmartAssistantService } from '../smart-assistant.service';

export function httpClientBaseUrlInterceptor(): HttpInterceptorFn {
  return (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    if (req.url.includes('/smart-assistant/client')) {
      if (!req.url.includes('extra/status') && !req.url.includes('configuration/status')) {
        SmartAssistantService.setServiceStatus(SmartCoreAssistantStatus.PROCESSING);
      }

      return next(req).pipe(
        tap(event => {
          if (event.type === HttpEventType.Response) {
            if (!req.url.includes('extra/status') && !req.url.includes('configuration/status')) {
              SmartAssistantService.setServiceStatus(SmartCoreAssistantStatus.ACTIVE);
            }
          }
        }),
        catchError(err => {
          if (!req.url.includes('extra/status') && !req.url.includes('configuration/status')) {
            SmartAssistantService.setServiceStatus(SmartCoreAssistantStatus.ACTIVE);
          }
          return throwError(() => err);
        }),
      );
    }
    return next(req);
  };
}
