import { HttpClient } from '@angular/common/http';
import { ExtraKnowledgeStatusResponse, KnowledgeDatabaseStatus } from '@smart-assistant/types/v2';
import { map, Observable } from 'rxjs';

import { FileService } from './categories/file.service';
import { HelpersService } from './categories/helpers.service';
import { TextService } from './categories/text.service';

export class KnowledgeService {
  #textService: TextService;
  #fileService: FileService;
  #helpersService: HelpersService;

  get text() {
    return this.#textService;
  }

  get file() {
    return this.#fileService;
  }

  get helpers() {
    return this.#helpersService;
  }

  constructor(private readonly httpClient: HttpClient) {
    this.#textService = new TextService(httpClient);
    this.#fileService = new FileService(httpClient);
    this.#helpersService = new HelpersService(httpClient);
  }

  getStatus(): Observable<KnowledgeDatabaseStatus> {
    return this.httpClient
      .get<ExtraKnowledgeStatusResponse>(`/smart-assistant/client/knowledge/extra/status`)
      .pipe(map(res => res.status));
  }

  setStatus(status: KnowledgeDatabaseStatus): Observable<KnowledgeDatabaseStatus> {
    return this.httpClient
      .put<ExtraKnowledgeStatusResponse>(`/smart-assistant/client/knowledge/extra/status`, {
        status,
      })
      .pipe(map(res => res.status));
  }
}
