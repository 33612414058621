<button
  [class.center]="centerText"
  [disabled]="disabled"
  [ngClass]="[sizeClass, typeClass, hasIcon, iconSize, blockType, hasBottomLabel]"
  class="button"
  type="submit"
>
  <ui-loader [color]="loaderColor" [showSpinner]="pending" [size]="loaderSize"></ui-loader>
  @if (showAvatar) {
    <ui-avatar [key]="avatarKey" [object]="object" [size]="avatarSize" [type]="avatarType"></ui-avatar>
  }
  @if (showIcon) {
    <svg-icon [class.color]="color" [src]="iconPath" class="icon"></svg-icon>
  }
  @if (label) {
    <span class="label">{{ label }}</span>
  }
  <ng-content></ng-content>
  @if (bottomLabel) {
    <span class="bottom-label">{{ bottomLabel | maxChars: 40 }}</span>
  }
  @if (showArrow) {
    <svg-icon class="arrow" src="/assets/icons/icons-arrow-down.svg"></svg-icon>
  }
  @if (iconRight) {
    <svg-icon class="icon-right" src="/assets/icons/{{ iconRight }}.svg"></svg-icon>
  }
</button>
