<div *ngIf="loaded" [class.textarea-wrapper]="placeholders">
  <p *ngIf="templateTop && !readonly">{{ templateTop }}</p>
  <quill-editor
    (drop)="false"
    (onContentChanged)="textChanged($event)"
    (onEditorCreated)="editorInit($event)"
    [class.readonly]="readonly"
    [formControl]="control"
    [formats]="allowedFormats"
    [modules]="editorConfig"
    [placeholder]="placeholder"
  ></quill-editor>
  <p *ngIf="templateBottom && !readonly">{{ templateBottom }}</p>
</div>
